import React, { useContext, useEffect, useState } from "react";
import { PageProps } from "gatsby";
import { Layout } from "../components/Layout";
import { Container, Jumbotron, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { LocalizedLink as Link } from "gatsby-theme-i18n";
import { citiesList, City } from '../data/citiesDropDownData';
import { Select } from "../components/select/Select";
import welcome from '../assets/welcome.svg';
import group5 from '../assets/group-5.svg';
import group3 from '../assets/group-3.svg';
import style from './index.module.scss';
import { DataContext } from "../states/dataContext";
// @ts-ignore
import { convertWorker } from '../workers/convertWorker';

export default function Index(props: PageProps) {
    const { t } = useTranslation();
    const { activeCity, selectCity } = useContext(DataContext);

    return (
        <Layout pageProps={props}>
            {/* Cover image */}
            <Jumbotron fluid className={`d-flex justify-content-center ${style.jumbotron}`}>
                <Container className="d-flex flex-column justify-content-center align-items-center">
                    {/* <Button
                        onClick={async () => {
                            if (typeof convertWorker !== 'boolean') {
                                // const timeStart = Math.round(Date.now() / 1000);
                                // console.log('START', timeStart);
                                // @ts-ignore
                                const result = await convertWorker().convert(jsonData);
                                // const timeEnd = Math.round(Date.now() / 1000);
                                // console.log('END', result.features[0]);
                            }
                        }}
                    >
                        Test it
                    </Button> */}

                    <img src={welcome} />
                    <p className={style.title} >{t("title")}</p>
                </Container>
            </Jumbotron>

            {/* Central text */}
            <Container className={`d-flex flex-column justify-content-center align-items-center mx-auto mt-5 ${style.centerParagraph}`}>
                <h2 className={style.sectionSubtitle}>{t("Welcome")}</h2>
                <p className={`${style.paragraph} ${style.rainfallDataParagraph}`}>
                    <b>{t("Home paragraph one")}</b>
                    <br /><br />
                    {t("Home paragraph two")}
                    <br /><br />
                    {t("Home paragraph tree")}
                </p>
            </Container>
            <Container className={`${style.centerParagraph}`}>
                <hr />
            </Container>
            {/* City select section */}
            <Container className={style.homeSectionMid} >
                <Row>
                    <Col className={style.homeSectionMidLeft}>
                        <h1 className={style.homeSectionMidTitle}>{t("Select your city")}</h1>

                        <Select
                            onSelect={(city: City) => selectCity(city)}
                            currentSelectedItem={activeCity}
                            selectItemsList={citiesList}
                            type="cities"
                        />

                        <p className={style.homeSectionMidParagraph}>
                            {activeCity.text ? t(activeCity.text) : ""}
                        </p>

                        <Row className={`d-flex flex-row align-items-center ${style.homeSectionMidTerms}`}>
                            <img src={activeCity.logo} />
                            {/* <Link to={activeCity.termsOfUseLink}>{t("Terms of use")}→</Link> */}
                        </Row>
                    </Col>

                    <Col className={style.sectionMidRight}>
                        <Link to={activeCity.exploreDataLink} state={{ city: activeCity }}>
                            <Row className={`d-flex justify-content-start align-content-start align-items-center ${style.sectionMidRightIcon}`}>
                                <img src={group3} />
                                <p className={style.sectionMidRightText}>{t("rainfall data btn")}</p>
                            </Row>
                        </Link>
                        <Link to={activeCity.communityForumLink} target="_blank">
                            <Row className="d-flex align-items-center">
                                <img src={group5} />
                                <p className={style.sectionMidRightText}>{t("Community forum")}</p>
                            </Row>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
}